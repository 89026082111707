// website/src/pages/terms-conditions/CommissionPage.js

import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

const CommissionPage = () => {
  return (
    <Layout headerWhite={true}>
      <Container maxWidth='xl' sx={{ textAlign: 'center', my: { xs: 6, md: 8 } }}>
        <Seo
          title='Terms & Conditions - Commission'
          description='Read our terms and conditions related to commissions.'
          pathname='/terms-conditions/commission'
        />
        <div className='text-center pt-s-64 pb-s-64'>
        <Typography variant='h3' gutterBottom>
          Pricing schedule for
          applicable commissions
        </Typography>
        <Typography variant='body3' paragraph>
        (Foursource Terms & Conditions, Clause 8.10, updated 22.12.2023)
        </Typography>
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '50%', backgroundColor:'#C3C7D0', borderRadius: '12px 0 0 0' }}>
                  <Typography variant='h4'>Net purchase price of order</Typography></TableCell>
                <TableCell align="center" style={{ backgroundColor:'#D5D7DD' }}><Typography variant='h4'>Buyer</Typography></TableCell>
                <TableCell align="center" style={{ backgroundColor:'#D5D7DD', borderRadius: '0 12px 0 0' }}><Typography variant='h4'>Seller</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{ backgroundColor:'#E6E8EF', borderRadius: '12px 0 0 0' }}>
                <TableCell><Typography variant='h4'>below 5,000 EUR/ USD</Typography></TableCell>
                <TableCell align="center">5%</TableCell>
                <TableCell align="center">9%</TableCell>
              </TableRow>
              <TableRow style={{ backgroundColor:'#EDF0F6', borderRadius: '12px 0 0 0' }}>
                <TableCell><Typography variant='h4'>between 5,000 and 10,000 EUR/ USD</Typography></TableCell>
                <TableCell align="center">4%</TableCell>
                <TableCell align="center">8%</TableCell>
              </TableRow>
            </TableBody>
            <TableRow style={{ backgroundColor:'#E6E8EF', borderRadius: '12px 0 0 0' }}>
                <TableCell><Typography variant='h4'>higher than 10,000 EUR/ USD</Typography></TableCell>
                <TableCell align="center">3%</TableCell>
                <TableCell align="center">7%</TableCell>

              </TableRow>
          </Table>
        </TableContainer>
      </Container>
      <div className='text-center pt-s-64 pb-s-64'></div>
    </Layout>
  );
};

export const Head = ({ location }) => (
  <Seo
    title='Terms & Conditions - Commission'
    description='Read our terms and conditions related to commissions.'
    pathname={location.pathname}
  />
);

export default CommissionPage;

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
